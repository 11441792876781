import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigConst } from 'src/app/core/util/config.const';
import { UserRegisterGuard } from './shared/guards/user-register-guard';
import { FinanceiroComponent } from './view/components/financeiro/financeiro.component';

const routes: Routes = [
  {
    path: ConfigConst.login,
    loadChildren: () =>
      import('./view/components/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: ConfigConst.recuperarsenha,
    loadChildren: () =>
      import('./view/components/recuperar-senha/recuperar-senha.module').then(
        (m) => m.RecuperarSenhaModule
      ),
  },
  {
    path: ConfigConst.novasenha,
    loadChildren: () =>
      import('./view/components/nova-senha/nova-senha.module').then(
        (m) => m.NovaSenhaModule
      ),
  },
  {
    path: ConfigConst.novaconta,
    canDeactivate: [UserRegisterGuard],
    loadChildren: () =>
      import('./view/components/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: ConfigConst.meuscontribuintes,
    loadChildren: () =>
      import('./view/components/contribuintes/contribuintes.module').then(
        (m) => m.ContribuintesModule
      ),
  },
  {
    path: ConfigConst.contratarPacote,
    loadChildren: () =>
      import('./view/components/contratar-pacote/contratar-pacote.module').then(
        (m) => m.ContratarPacoteModule
      ),
  },
  {
    path: ConfigConst.adicionarcontribuintes,
    loadChildren: () =>
      import(
        './view/components/adicionarcontribuinte/adicionarcontribuinte.module'
      ).then((m) => m.AdicionarcontribuinteModule),
  },
  {
    path: ConfigConst.atualizarcontribuintes,
    loadChildren: () =>
      import(
        './view/components/atualizarcontribuinte/atualizarcontribuinte.module'
      ).then((m) => m.AtualizarcontribuinteModule),
  },
  {
    path: `${ConfigConst.ativarconta}/:email`,
    loadChildren: () =>
      import('./view/components/activeusers/activeusers.module').then(
        (m) => m.ActiveusersModule
      ),
  },
  {
    path: ConfigConst.meuscertificados,
    loadChildren: () =>
      import('./view/components/certificados/certificados.module').then(
        (m) => m.CertificadosModule
      ),
  },
  {
    path: ConfigConst.rotaminhascontabilidades,
    loadChildren: () =>
      import('./view/components/contabilidades/contabilidades.module').then(
        (m) => m.ContabilidadesModule
      ),
  },
  // vvvvvv OS DOIS CAMINHOS ABAIXO CHAMAM O MESMO COMPONENTE vvvvvv
  {
    path: ConfigConst.adicionarcertificado,
    loadChildren: () =>
      import(
        './view/components/adicionarcertificado/adicionarcertificado.module'
      ).then((m) => m.AdicionarcertificadoModule),
  },
  {
    path: ConfigConst.rotaadicionarcontabilidade,
    loadChildren: () =>
      import(
        './view/components/adicionarcertificado/adicionarcertificado.module'
      ).then((m) => m.AdicionarcertificadoModule),
  },
  // ^^^^^^ OS DOIS CAMINHOS ABAIXO CHAMAM O MESMO COMPONENTE ^^^^^^
  {
    path: ConfigConst.rotausuariocontabilidade,
    loadChildren: () =>
      import(
        './view/components/usuariocontabilidade/usuariocontabilidade.module'
      ).then((m) => m.UsuariocontabilidadeModule),
  },
  {
    path: ConfigConst.resetsenha,
    loadChildren: () =>
      import('./view/components/reset-senha/reset-senha.module').then(
        (m) => m.ResetSenhaModule
      ),
  },
  {
    path: ConfigConst.usuariocontribuinte,
    loadChildren: () =>
      import(
        './view/components/usuariocontribuinte/usuariocontribuinte.module'
      ).then((m) => m.UsuariocontribuinteModule),
  },
  {
    path: ConfigConst.plugincaptura,
    loadChildren: () =>
      import('./view/components/plugin-captura/plugin-captura.module').then(
        (m) => m.PluginCapturaModule
      ),
  },
  {
    path: ConfigConst.meusdados,
    loadChildren: () =>
      import('./view/components/meus-dados/meus-dados.module').then(
        (m) => m.MeusDadosModule
      ),
  },
  {
    path: ConfigConst.revisaotributaria,
    loadChildren: () =>
      import(
        './view/components/revisaotributaria/revisaotributaria.module'
      ).then((m) => m.RevisaotributariaModule),
  },
  {
    path: ConfigConst.home,
    loadChildren: () =>
      import('./shared/components/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: `${ConfigConst}/:permissao`,
    loadChildren: () =>
      import('./view/components/retornoemail/retornoemail.module').then(
        (m) => m.RetornoemailModule
      ),
  },
  {
    path: ConfigConst.autenticar,
    loadChildren: () =>
      import('./view/components/autenticar/autenticar.module').then(
        (m) => m.AutenticarModule
      ),
  },
  {
    path: ConfigConst.financeiro,
    component: FinanceiroComponent,
  },
  {
    path: ConfigConst.recuperarconta,
    loadChildren: () =>
      import('./view/components/retrive-account/retrive-account.module').then(
        (m) => m.RetriveAccountModule
      ),
  },
  {
    path: ConfigConst.comece,
    loadChildren: () =>
      import('./view/components/comece/comece.module').then(
        (m) => m.ComeceModule
      ),
  },
  {
    path: ConfigConst.formularioPGDAS,
    loadChildren: () =>
      import('./view/components/formulario-pgdas/formulario-pgdas.module').then(
        (m) => m.FormularioPGDASModule
      ),
  },
  {
    path: ConfigConst.etapa,
    loadChildren: () =>
      import('./shared/components/etapa-info/etapa-info.module').then(
        (m) => m.EtapaInfoModule
      ),
  },
  {
    path: `${ConfigConst.relatorio}2/:parametros`,
    // ESSA ROTA JA ESTAVA INVERTIDA!!
    // component: RelatorioComponent,
    loadChildren: () =>
      import('./view/components/relatorio/relatorio.module').then(
        (m) => m.RelatorioModule
      ),
  },
  {
    path: ConfigConst.produtoemitente,
    loadChildren: () =>
      import('./view/components/produto-emitente/produto-emitente.module').then(
        (m) => m.ProdutoEmitenteModule
      ),
  },
  {
    path: ConfigConst.participanteemitente,
    loadChildren: () =>
      import('./view/components/participante/participante.module').then(
        (m) => m.ParticipanteModule
      ),
  },
  {
    path: ConfigConst.cenarios,
    loadChildren: () =>
      import(
        './view/components/cenarios-override/cenarios-override.module'
      ).then((m) => m.CenariosOverrideModule),
  },
  {
    path: ConfigConst.referencial,
    loadChildren: () =>
      import('./view/components/referencial/referencial.module').then(
        (m) => m.ReferencialModule
      ),
  },
  {
    path: ConfigConst.nfstomadas,
    loadChildren: () =>
      import('./view/components/nfs-tomadas/nfs-tomadas.module').then(
        (m) => m.NFSTomadasModule
      ),
  },
  {
    path: ConfigConst.consultalivredeprodutos,
    // component: ConsultasComponent, -- JA EXISTIA ESSE COMENTARIO
    loadChildren: () =>
      import(
        './view/components/consulta-livre-produto/consulta-livre-produto.module'
      ).then((m) => m.ConsultaLivreProdutoModule),
  },
  {
    path: ConfigConst.simuladordeoperacoes,
    loadChildren: () =>
      import(
        './view/components/consulta-interestadual/consulta-interestadual.module'
      ).then((m) => m.ConsultaInterestadualModule),
  },
  {
    path: ConfigConst.confrontarsped,
    loadChildren: () =>
      import('./view/components/confrontar-sped/confrontar-sped.module').then(
        (m) => m.ConfrontarSpedModule
      ),
  },
  {
    path: ConfigConst.calcularguia,
    loadChildren: () =>
      import('./view/components/calcular-guia/calcular-guia.module').then(
        (m) => m.CalcularGuiaModule
      ),
  },
  {
    path: ConfigConst.segregacao,
    loadChildren: () =>
      import('./view/components/segregacao/segregacao.module').then(
        (m) => m.SegregacaoModule
      ),
  },

  {
    path: ConfigConst.pgdas,
    loadChildren: () =>
      import('./view/components/pgdas/pgdas.module').then((m) => m.PGDASModule),
  },
  {
    path: `${ConfigConst.segregacao}2`,
    loadChildren: () =>
      import('./view/components/calculadora/calculadora.module').then(
        (m) => m.CalculadoraModule
      ),
  },
  {
    path: `${ConfigConst.verdocumento}/:codigo`,
    loadChildren: () =>
      import('./view/components/ver-documento/ver-documento.module').then(
        (m) => m.VerDocumentoModule
      ),
  },
  {
    // ESSE PATH É REPETIDO COM O simulador-de-operacoes !
    path: ConfigConst.consultainterestadual,
    loadChildren: () =>
      import(
        './view/components/consulta-interestadual/consulta-interestadual.module'
      ).then((m) => m.ConsultaInterestadualModule),
  },
  {
    path: ConfigConst.emalote,
    loadChildren: () =>
      import('./view/components/e-malote/e-malote.module').then(
        (m) => m.EMaloteModule
      ),
  },
  {
    path: ConfigConst.emalotedetails,
    loadChildren: () =>
      import('./view/components/e-malote-details/e-malote-details.module').then(
        (m) => m.EMaloteDetailsModule
      ),
  },
  {
    path: ConfigConst.visaosituacional,
    loadChildren: () =>
      import(
        './view/components/visao-situacional/visao-situacional.module'
      ).then((m) => m.VisaoSituacionalModule),
  },
  {
    path: 'analise-consolidada',
    loadChildren: () =>
      import('./view/components/dashpower/dashpower.module').then(
        (m) => m.DashpowerModule
      ),
  },
  {
    path: ConfigConst.gerenciarintegracoes,
    loadChildren: () =>
      import(
        './view/components/gerenciar-integracoes/gerenciar-integracoes.module'
      ).then((m) => m.GerenciarIntegracoesModule),
  },
  {
    path: 'cockpit-vendas',
    loadChildren: () =>
      import('./view/components/cockpit/cockpit.module').then(
        (m) => m.CockpitModule
      ),
  },
  {
    path: 'importar-chaves',
    loadChildren: () =>
      import('./view/components/importar-chaves/importar-chaves.module').then(
        (m) => m.ImportarChavesModule
      ),
  },
  {
    path: 'integracoes',
    loadChildren: () =>
      import('./view/components/integracoes/integracoes.module').then(
        (m) => m.IntegracoesModule
      ),
  },

  {
    path: 'recuperacao-simples-nacional',
    loadChildren: () =>
      import(
        './view/components/recuperacao-simples-nacional/recuperacao-simples-nacional.module'
      ).then((m) => m.RecuperacaoSimplesNacionalModule),
  },
  /*,
  {
    path: 'dashboard',
    redirectTo: 'w',
    canActivate: [AuthGuard]
  },*/
];

if (ConfigConst.APP === 'impostocerto') {
  routes.push(
    {
      path: 'confrontar-poc',
      loadChildren: () =>
        import('./view/components/confrontar-poc/confrontar-poc.module').then(
          (m) => m.ConfrontarPocModule
        ),
    },
    // {
    //   path: ConfigConst.dashmodulointegracao,
    //   loadChildren: () =>
    //     import(
    //       './view/components/dashmodulointegracao/dashmodulointegracao.module'
    //     ).then((m) => m.DashmodulointegracaoModule),
    // },
    {
      path: ConfigConst.dashmodulointegracao,
      loadChildren: () =>
        import(
          './view/components/dashboard-modulo-integracoes/dashboard-modulo-integracoes.module'
        ).then((m) => m.DashboardModuloIntegracoesModule),
    },
    {
      path: ConfigConst.auditoria,
      loadChildren: () =>
        import(
          './view/components/impostocerto/auditar-documentos/auditar-documentos.module'
        ).then((m) => m.AuditarDocumentosModule),
    },
    {
      path: `${ConfigConst.relatorio}/:parametros`,
      loadChildren: () =>
        import(
          './view/components/impostocerto/relatorio-auditoria/relatorio-auditoria.module'
        ).then((m) => m.RelatorioAuditoriaImpostoCertoModule),
    }
  );
} else {
  routes.push(
    {
      path: ConfigConst.auditoria,
      loadChildren: () =>
        import('./view/components/auditoria/auditoria.module').then(
          (m) => m.AuditoriaModule
        ),
    },
    {
      path: `${ConfigConst.relatorio}/:parametros`,
      // ESSA ROTA JA ESTAVA INVERTIDA!!
      // component: Relatorio2Component,
      loadChildren: () =>
        import(
          './view/components/relatorio-auditoria/relatorio-auditoria.module'
        ).then((m) => m.RelatorioAuditoriaModule),
    }
  );
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
